<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>包裹管理</el-breadcrumb-item>
      <el-breadcrumb-item>包裹列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">

      <div class="change">
        <el-input v-model="queryInfo.expressWaybillNum" clearable placeholder="请输入快递单号"></el-input>
        <el-input v-model="queryInfo.sender" clearable placeholder="请输入寄件人名称"></el-input>
        <el-input v-model="queryInfo.senderPhone" clearable placeholder="请输入寄件人手机号"></el-input>
        <el-input v-model="queryInfo.consignee" clearable placeholder="请输入收件人名称"></el-input>
        <el-input v-model="queryInfo.consigneePhone" clearable placeholder="请输入收件人手机号"></el-input>
        <el-button type="primary" @click="search">查询</el-button>
        <!-- <el-button>导出</el-button> -->
      </div>
      <!-- </el-card> -->
      <!-- 卡片视图 -->

      <!-- <el-card shadow="never"> -->

      <!-- 用户信息框 -->
      <el-table :data="parcelList" border stripe style="width: 100%"
        :header-cell-style="{ background: 'rgba(22, 119, 255, 0.0300)' }">
        <el-table-column type="index" align="center"> </el-table-column>
        <el-table-column prop="expressWaybillNum" label="快递单号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="postCompanyName" label="快递公司" width="100" align="center">
        </el-table-column>
        <el-table-column prop="sender" label="寄件人" width="110" align="center">
        </el-table-column>
        <el-table-column prop="senderPhone" label="寄件人手机号" width="135" align="center">
        </el-table-column>
        <el-table-column prop="senderAddress" label="寄件人地址" width="225" align="center">
        </el-table-column>
        <el-table-column prop="consignee" label="收件人" width="105" align="center">
        </el-table-column>
        <el-table-column prop="consigneePhone" label="收件人手机号" width="125" align="center">
        </el-table-column>
        <el-table-column prop="consigneeAddress" label="收件人地址" width="225" align="center">
        </el-table-column>
        <el-table-column prop="status" label="物流状态" align="center" width="140">
          <template slot-scope="scope">
            {{ map[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="logistics = true">物流信息</el-button> -->
            <el-button type="text" @click="goLogistics(scope.row)">物流派单</el-button>
            <el-button type="text" @click="goRecord(scope.row)">派单记录</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.current" :page-sizes="[10, 20, 50, 100, 200]" :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 物流信息 -->
    <!-- <el-dialog title="收货地址" width="70%" :visible.sync="logistics">
      <el-table :data="logisticsTable" border>
        <el-table-column label="快递单号" property="number" width="150" align="center"></el-table-column>
        <el-table-column label="快递公司" property="company" align="center" width="200"></el-table-column>
        <el-table-column label="操作人" align="center" property="operator"></el-table-column>
        <el-table-column label="操作时间" align="center" property="time" width="150"></el-table-column>
        <el-table-column label="操作类型" align="center" property="type" width="200"></el-table-column>
        <el-table-column label="" align="center" property="" width="150"></el-table-column>
        <el-table-column label="" align="center" property="" width="200"></el-table-column>
        <el-table-column label="" align="center" property=""></el-table-column>
        <el-table-column label="操作" align="center" property="operation"></el-table-column>
      </el-table>
    </el-dialog> -->
    <el-dialog width="30%" border :visible.sync="logistics">
      站点名称
      <el-select v-model="form.dispatchSiteNum" placeholder="请选择站点" style="margin-left:20px">
        <el-option v-for="item in siteList" :key="item.id" :label="item.siteName" :value="item.siteNum">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logistics = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="派单记录" width="50%" border :visible.sync="record" :show-close="false">
      <el-table :data="recordList" border>
        <el-table-column label="时间" property="createTime" align="center"></el-table-column>
        <el-table-column label="当前站点" property="siteName" align="center"></el-table-column>
        <el-table-column label="派单站点" property="dispatchSiteName" align="center"></el-table-column>
        <el-table-column label="操作人" property="operatorName" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 控制物流信息的显示与隐藏
      logistics: false,
      record: false,
      // logistics: false,
      parcelList: [],
      recordList: [],
      queryInfo: {
        consignee: '',
        consigneePhone: '',
        current: 1,
        expressWaybillNum: '',
        sender: '',
        senderPhone: '',
        size: 10,
      },
      form: {
        expressWaybillNum: '',
        dispatchSiteNum: '',
      },
      siteList: [],
      total: 0,
      map: ['', '在库', '出库', '签收', '拒签', '快递运送中'],
    }
  },
  // 钩子   创建后执行此函数
  created() {
    this.getParcelList()
    this.changeSite()
  },
  methods: {
    // 站点选择器列表
    changeSite() {
      this.axios.get('/api/site/info/getSiteList').then((res) => {
        // console.log(res)
        this.siteList = res.data.data
      })
    },
    goLogistics(row) {
      this.logistics = true
      this.form.expressWaybillNum = row.expressWaybillNum
    },
    ok() {
      this.axios.post('/api/site/express/dispatch', this.form).then((res) => {
        console.log(res)
        this.logistics = false
      })
    },
    goRecord(row) {
      this.record = true
      this.axios.get('/api/site/express/dispatchRecord?expressWaybillNum=' + row.expressWaybillNum).then((res) => {
        this.recordList = res.data.data
      })
    },
    // 获取包裹列表
    getParcelList() {
      this.axios.post('/api/site/express/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.parcelList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.parcelList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      // console.log(newSize);
      this.queryInfo.size = newSize
      this.getParcelList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getParcelList()
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getParcelList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}

.el-input {
  margin-right: 8px;
  width: 240px;
}

.el-card {
  margin-bottom: 20px;

  .add {
    width: 86px;
  }
}

.change {
  margin: 20px 0;
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.aaa {
  margin-left: 8px;
}
</style>
      
    